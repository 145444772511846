import React from 'react';
import {Link} from "react-router-dom";
import clsx from "clsx";
import Header from './header';
import './layout.css';

function Layout({className, children, title, ...props}) {
  return (
    <div className={clsx("layout", className)}>
      <Header className="layout-header bordered" {...props} />
      <main className="layout-main bordered">
        <section className="layout-content">{children}</section>
      </main>
      <footer className="layout-footer bordered">© 2021 SmartWork - <Link className="legal" to="/legal">Legal</Link>
      </footer>
    </div>
  );
}

export default Layout;

import React from 'react';
import {Link,} from "react-router-dom";
import logo from '../assets/logo_sm.png'
import './header.css'


const Header = () => {
  return (
    <header className='header'>
      <Link className="header-logo" to="/">
        <img className='logo' alt='smartwork logo' src={logo}></img>
      </Link>
      <div className='nav'>
        <h3 className='title'>¿Eres comercial técnico? </h3>
        <Link className='button' to="/te-queremos">¡Te queremos!</Link>
      </div>
      <div className='nav mobile'>
        <Link className='button' to="/te-queremos">¿Eres comercial?</Link>
      </div>
    </header>
  )
}

export default Header;




import HomePage from './components/homePage';
import CvPage from './components/cvPage';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import LegalPage from "./components/legal";

function App() {
  return (
    <Router>
      <Route  path='/legal' component={LegalPage}/>
      <Route  path='/te-queremos' component={CvPage}/>
      <Route exact path='/' component={HomePage}/>
    </Router>
  );
}

export default App;

import React from 'react';
import { Link  } from "react-router-dom";
import HeaderCv from './headerCv';
import './layout.css';

function LayoutCV({children, title, ...props}) {
  return (
    <div className="layout">
      <HeaderCv className="layout-header bordered" {...props} />
      <main className="layout-main bordered">
        <section className="layout-content">{children}</section>
      </main>
      <footer className="layout-footer bordered">© 2021 SmartWork - <Link className="legal" to="/legal">Legal</Link></footer>
    </div>
  );
}

export default LayoutCV;

import React from "react";
import cvphoto from "../assets/imagen2.png";
import "./homePage.css";
import LayoutCV from "../layout/layoutCV";
import ButtonContact from "./buttonContact";

const CvPage = () => {
  return (
    <LayoutCV>
      <div className="container">
        <div className="textContainer">
          <h1 className="titleHome">Te buscamos a ti</h1>
          <p className="textHome">
            En Smartwork estamos buscando comerciales (freelancers, autónomos o
            pymes) con experiencia en el sector IT para unirse a nuestro equipo.{" "}
            <br />
            Ofrecemos importantes comisiones.
          </p>
          <p className="commissions">
            15-20% del presupuesto total del proyecto.
          </p>
          <ButtonContact
            className="button-contact"
            label="Envía tu CV"
            mailto="mailto:recruiting@smartwork.dev"
          />
        </div>
        <div className="containerPhoto">
          <img
            className="HomePhoto"
            src={cvphoto}
            alt="comercial mirando el ipad"
          />
        </div>
      </div>
    </LayoutCV>
  );
};

export default CvPage;

import React from 'react';
import {Link} from 'react-router-dom';

const ButtonContact = ({className, mailto, label}) => {
    return (
        <Link
            className={className}
            to='#'
            onClick={(e) => {
                window.location=mailto;
                e.preventDefault();
            }}
            >
            {label}
        </Link>    
    );
};

export default ButtonContact;
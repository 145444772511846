import React from 'react';
import { Link  } from "react-router-dom";
import logo from '../assets/logo_sm.png'
import './header.css'


const HeaderCv = () => {
    return (
        <header className='header'>
          <Link className="header-logo" to="/">
            <img className='logo' alt='smartwork logo' src={logo} />
          </Link>
        </header>
    )
}

export default HeaderCv;

import React from 'react';
import homephoto from '../assets/imagen1.png';
import './homePage.css';
import Layout from '../layout/layout';
import ButtonContact from './buttonContact'

const HomePage = () => {

    return (
        <Layout>
            <div className='container'>
                <div className='textContainer'>
                    <h1 className='titleHome'>Dale forma a tu negocio con ingeniería Smart</h1>
                    <p className='textHome'>Actualmente nuestros desarolladores están trabajando
                        en esta web. Muy pronto estará totalmente disponible.</p>
                    <ButtonContact className='button-contact' label='Contáctanos' mailto='mailto:info@smartwork.dev' />
                </div>
                <div className='containerPhoto'>
                    <img className='HomePhoto' alt='4 personas usando mascarillas, con portátiles y mirando un móvil' src={homephoto} />
                </div>
            </div>
        </Layout>


    )
}

export default HomePage;
